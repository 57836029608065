import { GlobalStyles } from "../types/theme";

export const globalStyles: GlobalStyles = () => ({
  html: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  body: {
    display: "flex !important",
    flexDirection: "column",
    flex: 1,
  },
  "#root": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100%",
    alignItems: "stretch",
  },
});
