import axios from "axios";

interface BodyShopDto {
  id: string;
  name: string;
  reference: string;
}

export const getBodyShop = (clientId: string, reference: string) => {
  return axios.get<BodyShopDto>(`/body-shop/${clientId}/${reference}`).then(({ data }) => data);
};
