import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import { getUserLocale } from "utils/environment";

import { configAtom } from "../atoms/config";
import { Locale, defaultNS } from "../types/locale";

export const i18nextConfig = {
  fallbackLng: Locale.English_GB, // Ensures English content will be used if in primary locale is missing/empty string
  defaultNS: defaultNS,
  load: "currentOnly" as const,
  returnEmptyString: false,
  react: {
    transKeepBasicHtmlNodesFor: ["b"],
  },
};

export const useInitialiseLocalisation = () => {
  const { locale } = useAtomValue(configAtom);

  useEffect(() => {
    i18n
      .use(ChainedBackend)
      .use(initReactI18next)
      .init({
        lng: getUserLocale(locale),
        ...i18nextConfig,
        backend: {
          backends: [
            HttpBackend,
            resourcesToBackend((lng, _ns, clb) => {
              import(`../fallbackContent/${lng}.json`) // This fallback is only used if the primary method fails
                .then((resources) => clb(null, resources))
                .catch((error) => clb(error, null));
            }),
          ],
          backendOptions: [
            {
              loadPath: "https://public.prod-use1.assets.tractable.io/coa-customer-pay/translations/{{lng}}.json",
            },
          ],
        },
      });
  }, [locale]);
};
