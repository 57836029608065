import { useAtomValue } from "jotai";
import { ReactNode } from "react";
import { TFuncKey, Trans, useTranslation } from "react-i18next";

import { configAtom } from "../../atoms/config";
import { getTranslationKey } from "../../hooks/useClientTranslation";

type ClientTranslationProps = {
  children: ReactNode;
  i18nKey: TFuncKey<"default">;
  [prop: string]: unknown;
};

export const ClientTranslation = ({ children, i18nKey, ...props }: ClientTranslationProps) => {
  const { i18n } = useTranslation();
  const { clientId, translationDicts = [] } = useAtomValue(configAtom);

  const configTranslationDict = translationDicts.length > 0 ? translationDicts : [clientId];
  const key = getTranslationKey(i18n, i18nKey, configTranslationDict);

  return (
    <Trans i18nKey={key} {...props}>
      {children}
    </Trans>
  );
};
