import { Locale } from "types/locale";

export const getUserLocale = (locale: Locale | Locale[]): Locale => {
    // If locale is a list, we get it from browser as long as it is in the list
    if (Array.isArray(locale)) {
        const langIdx = locale.findIndex(loc => loc === navigator.language)
        return langIdx < 0 ? locale[0] : locale[langIdx]
    }
    return Array.isArray(locale) ? locale[0] : locale
}
