import { track } from "@amplitude/marketing-analytics-browser";
import { useCallback } from "react";

import { isLocalEnvironment, isTestEnvironment } from "../utils/environment";

import { Event } from "../types/Event";

export const useEventTracking = () => {
  const trackEvent = useCallback((event: Event) => {
    // Track events in unit tests but not on local dev instances
    if (isLocalEnvironment() && !isTestEnvironment()) {
      return;
    }

    track(event.name, event.properties);
  }, []);

  return { trackEvent };
};
