import { createStyles, Text } from "@mantine/core";
import { FC } from "react";

import { TEXT_STYLES } from "../Text";

import { ErrorText } from "./ErrorText";

const useStyles = createStyles((theme) => TEXT_STYLES);
interface InputWithLabelProps {
  label: string;
  errorMessage: string | boolean;
}

export const InputWithLabel: FC<InputWithLabelProps> = ({ children, label, errorMessage }) => {
  const { classes } = useStyles();

  return (
    <>
      <Text className={classes.MediumMedium}>{label}</Text>
      {children}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </>
  );
};
