import { createStyles } from "@mantine/core";
import { useAtomValue } from "jotai";
import { FC } from "react";

import { configAtom } from "atoms/config";

import { AppFooter } from "../components/AppFooter";
import { AppHeader } from "../components/AppHeader";

const useStyles = createStyles((_, url: string) => ({
  image: {
    width: "50%",
    backgroundImage: `URL(${url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "@media (max-width: 1080px)": {
      display: "none",
    },
  },
  mainWrapper: {
    display: "flex",
    flexGrow: 1,
  },
  main: {
    width: "50%",
    minWidth: "375px",
    padding: "32px 48px",
    flexGrow: 1,
    flexShrink: 0,
    "@media (max-width: 480px)": {
      padding: "24px",
      minWidth: "0px",
    },
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const Layout: FC = ({ children }) => {
  const {
    formPage: {
      illustrations: {
        background: { url },
      },
    },
  } = useAtomValue(configAtom);
  const { classes } = useStyles(url);
  return (
    <>
      <AppHeader />
      <div className={classes.mainWrapper}>
        <div className={classes.main}>{children}</div>
        <div className={classes.image}></div>
      </div>
      <AppFooter />
    </>
  );
};
