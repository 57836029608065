import { datadogRum } from "@datadog/browser-rum";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, Input, createStyles } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { formatIncompletePhoneNumber, parseIncompletePhoneNumber } from "libphonenumber-js";
import { ChangeEvent, useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import useClientDetails from "hooks/useClientDetails";

import { submitForm } from "../../api/submitForm";
import { phoneNumberAtom, sessionFinishedAtom } from "../../atoms";
import { useClientTranslation } from "../../hooks/useClientTranslation";
import { useEventTracking } from "../../hooks/useEventTracking";
import ClientTranslation from "../ClientTranslation";
import { ErrorText } from "./ErrorText";
import { InputWithLabel } from "./InputWithLabel";
import { COUNTRY_CODE, schema } from "./validation";
import { configAtom } from "atoms/config";

const useStyles = createStyles((theme) => ({
  invalidCheckbox: {
    input: {
      border: `1px solid ${theme.colors.negative[6]}`,
    },
    label: {
      color: `${theme.colors.negative[6]}`,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "328px",
    margin: "32px 0",
    gap: "24px",
  },
  checkbox: {
    alignItems: "inherit",
  },
}));

const getUrlQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const urlQueryParams: { [key: string]: string } = {}; 
  for (const [key, value] of Array.from(searchParams.entries())) {
    urlQueryParams[key] = value;
  }
  return urlQueryParams;
};

export const Form = () => {
  const testing = process.env.NODE_ENV === "test";
  const { trackEvent } = useEventTracking();

  const { clientId, brand, urlConfigId, userLocale } = useClientDetails();
  const { bodyShopId } = useParams<{ bodyShopId: string }>();
  const navigate = useNavigate();
  const urlQueryParams = getUrlQueryParams();

  const [refreshReCaptcha, setRefreshCaptcha] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const setPhoneNumberAtom = useSetAtom(phoneNumberAtom(bodyShopId));
  const setSessionFinished = useSetAtom(sessionFinishedAtom(bodyShopId));
  const { formPage: { contactLink = "" } } = useAtomValue(configAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { classes } = useStyles();
  const { t } = useClientTranslation();

  const { mutate, isLoading, isError } = useMutation<any, any, FieldValues>(
    ({ firstName, lastName, email, phoneNumber, captchaToken }) =>
      submitForm({
        firstName,
        lastName,
        email,
        phoneNumber,
        captchaToken,
        bodyShopId,
        clientId,
        brand,
        userLocale,
        configId: urlConfigId,
        utmSource: urlQueryParams.utm_source,
      }),
    {
      retry: 3,
      networkMode: "always",
      retryDelay() {
        // As long as "value" is inverted, it'll reset the captcha
        // token
        setRefreshCaptcha((value) => !value);
        // Jitter is added to the retry attempt to prevent the
        // server from being flooded by requests at the same time
        const jitter = Math.random() * 1000;
        return 3000 + jitter;
      },
      onSuccess: (res) => {
        localStorage.setItem("app-link", res.appLink);
        setSessionFinished(true);
        setPhoneNumberAtom(phoneNumber);
        trackEvent({ name: "Form - send link", properties: { claimId: res.claimId} });
        navigate("./link_sent");
      },
      onError: (error: AxiosError) => {
        if (!(error.response?.status === 422)) {
          datadogRum.addError(error, { message: "Failed to submit form", bodyShopId });
        }
      },
    }
  );

  const onFormSubmit = (values: FieldValues) => {
    mutate(values);
  };

  const handleVerify = useCallback(
    (token: string) => {
      setValue("captchaToken", token);
    },
    [setValue]
  );

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={classes.form}>
        <div>
          <InputWithLabel label={t("form.first_name")} errorMessage={errors.firstName?.message}>
            <Input {...register("firstName")} invalid={!!errors.firstName} data-testid="first-name-input" />
          </InputWithLabel>
        </div>
        <div>
          <InputWithLabel label={t("form.last_name")} errorMessage={errors.lastName?.message}>
            <Input {...register("lastName")} invalid={!!errors.lastName} data-testid="last-name-input" />
          </InputWithLabel>
        </div>
        <div>
          <InputWithLabel label={t("form.email_address")} errorMessage={errors.email?.message}>
            <Input
              {...register("email")}
              placeholder={t("form.email_placeholder")}
              invalid={!!errors.email}
              data-testid="email-input"
            />
          </InputWithLabel>
        </div>
        <div>
          <InputWithLabel label={t("form.phone_number")} errorMessage={errors.phoneNumber?.message}>
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue={phoneNumber}
              render={({ field: { onChange, value } }) => {
                // This is a bit bad but there are no other way :(
                // TODO: Find better library for phone number validation.
                // https://github.com/catamphetamine/react-phone-number-input/blob/master/source/InputBasic.js#L36
                return (
                  <Input
                    placeholder={t("form.phone_placeholder")}
                    invalid={!!errors.phoneNumber}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      let newValue = parseIncompletePhoneNumber(e.target.value);
                      if (newValue === value) {
                        const formattedValue = formatIncompletePhoneNumber(value, COUNTRY_CODE);
                        if (formattedValue.indexOf(e.target.value) === 0) {
                          newValue = newValue.slice(0, -1);
                        }
                      }
                      setPhoneNumber(newValue);
                      return onChange(newValue);
                    }}
                    value={formatIncompletePhoneNumber(value, COUNTRY_CODE)}
                    data-testid="phone-input"
                  />
                );
              }}
            />
          </InputWithLabel>
        </div>
        <Checkbox
          {...register("toc")}
          className={`${classes.checkbox} ${errors.toc && classes.invalidCheckbox}`}
          label={t("form.acceptance")}
          data-testid="toc-input"
        />
        <div>
          <Button
            type="submit"
            variant="filled"
            color="secondary"
            fullWidth
            data-testid="submit-button"
            loading={isLoading}
          >
            <ClientTranslation i18nKey={"form.submit"}>{t("form.submit")}</ClientTranslation>
          </Button>
          {errors.apiError?.message && <ErrorText>{errors.apiError.message}</ErrorText>}
          {isError && (
            <ErrorText>
              <ClientTranslation i18nKey={"form.submit.error"}>
                anything
                <a href={contactLink} target="_blank" rel="noreferrer">
                  here
                </a>
                anything
              </ClientTranslation>
            </ErrorText>
          )}
        </div>
      </div>

      {!testing && <GoogleReCaptcha refreshReCaptcha={refreshReCaptcha} onVerify={handleVerify} />}
    </form>
  );
};
