import { FC } from "react";
import { createStyles, Text } from "@mantine/core";
import { ErrorIcon } from "../Icons/ErrorIcon";

import { TEXT_STYLES } from "../Text";

const useStyles = createStyles((theme) => ({
  errorOutline: {
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
    gap: "8px"
  },
  ...TEXT_STYLES,
  alignIcon: {
    marginTop: "5px"
  }
}));


export const ErrorText: FC = ({ children }) => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.errorOutline}>
      <div className={classes.alignIcon}><ErrorIcon /></div>
      <div>
        <Text color={theme.colors.negative[6]} className={classes.MediumRegular}>
          {children}
        </Text>
      </div>
    </div>
  );
};
