import { i18n as I18nInstance, TOptionsBase } from "i18next";
import { useAtomValue } from "jotai";
import { TFuncKey, useTranslation } from "react-i18next";

import { configAtom } from "../atoms/config";

interface InterpolatedOptions extends TOptionsBase {
  amount?: string;
}

type DefaultTFuncKey = TFuncKey<"default">;

// A helper function to build a custom translation key using a given a dictionaryKey and i18nKey.
const buildCustomKey = (dictionaryKey: string, i18nKey: DefaultTFuncKey): DefaultTFuncKey =>
  `${dictionaryKey}.${i18nKey}` as DefaultTFuncKey;

// A function to determine the appropriate translation key based on the existence of custom translations.
export const getTranslationKey = (
  i18n: I18nInstance,
  i18nKey: DefaultTFuncKey,
  translationDicts?: string[]
): DefaultTFuncKey => {
  // If a translationPrefixes is provided, try to find a translation matching
  // any of the given prefixes starting from the first element of the dictionary
  if (translationDicts && translationDicts.length > 0) {
    const foundPrefix = translationDicts.find((key) => i18n.exists(buildCustomKey(key, i18nKey)));
    if (foundPrefix) {
      return buildCustomKey(foundPrefix, i18nKey);
    }
  }

  // If no custom translation was found, use the default translation key.
  return i18nKey;
};

export const useClientTranslation = () => {
  const { t: i18nT, i18n } = useTranslation();
  // This can be used to add different translation dictionaries
  const { clientId, translationDicts = [] } = useAtomValue(configAtom);
  const configTranslationDict = translationDicts.length > 0 ? translationDicts : [clientId];

  const t = (i18nKey: DefaultTFuncKey, options?: InterpolatedOptions): string => {
    const key = getTranslationKey(i18n, i18nKey, configTranslationDict);

    return i18nT(key, options);
  };

  return { t, i18n };
};
