import { atom } from "jotai";

export const urlConfigIdAtom = atom<string>(() => {
  const [configId] = window.location.hostname.split(".");

  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    return process.env.REACT_APP_CLIENT_ID || configId || "tractable";
  }

  return configId;
});
