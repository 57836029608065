import enFallbackContent from "../mocks/fixtures/en-GB.json";

export enum Locale {
  Chinese = "zh-Hans-CN",
  Dutch = "nl",
  English_GB = "en-GB",
  English_US = "en-US",
  English_CA = "en-CA",
  French = "fr",
  French_CA = "fr-CA",
  German = "de",
  Italian_IT = "it-IT",
  Japanese_JP = "ja-JP",
  Polish = "pl",
  Portuguese_BR = "pt-BR",
  Portuguese_PT = "pt-PT",
  Spanish_ES = "es-ES",
  Thai = "th",
}

export const defaultNS = "default";

export const resources = {
  [Locale.English_GB]: {
    default: enFallbackContent,
  },
};

export type TranslationKeys = keyof typeof resources[Locale.English_GB]["default"];
