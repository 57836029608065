import { useEffect, useState } from "react";

import { getBodyShop } from "api/getBodyShop";

type PageValidationStrategies = "bodyShopExists";

interface PageValidationResult {
  loading: boolean;
  valid?: boolean;
}

interface StrategyInputs {
  clientId: string;
  reference?: string;
}

interface PageValidation extends StrategyInputs {
  strategy?: PageValidationStrategies;
}

type Strategy = (inputs: StrategyInputs) => Promise<boolean>;
type ValidationStrategies = { [key in PageValidationStrategies]: Strategy };

// Loads the body shop from the API to check it exists, if we show body shop information on
// the form page we could change how this was implemented to use useQuery/jotai etc but that is
// hard here as we're outside of hook land
const bodyShopExistsStrategy: Strategy = async ({ clientId, reference }) => {
  if (!reference) {
    return false;
  }

  // This will return 404 and therefore error if no body shop exists
  // While this logic is a bit dumb we have to validate the body shop exists
  // otherwise PDF generation and other features will fail down stream
  try {
    await getBodyShop(clientId, reference);
  } catch (err) {
    return false;
  }

  return true;
};

const validationStrategies: ValidationStrategies = {
  bodyShopExists: bodyShopExistsStrategy,
};

export default function usePageValidation({ strategy, clientId, reference }: PageValidation): PageValidationResult {
  const [state, setState] = useState<PageValidationResult>({ loading: true });

  useEffect(() => {
    (async () => {
      if (!strategy) {
        setState({ loading: false, valid: true });
        return;
      }

      const strategyFn = validationStrategies[strategy];

      if (!strategyFn) {
        setState({ loading: false, valid: true });
        return;
      }

      const valid = await strategyFn({ clientId, reference });
      setState({ loading: false, valid });
    })();
  }, [strategy, clientId, reference]);

  return state;
}
