import { datadogRum } from "@datadog/browser-rum";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";

import { getConfig } from "../api/getConfig";
import { sessionConfigAtom } from "../atoms/config";
import { urlConfigIdAtom } from "../atoms/urlConfigIdAtom";
import { ClientConfig } from "../clientConfig";

export const useInitialiseConfigFromConfigId = () => {
  const configId = useAtomValue(urlConfigIdAtom);
  const setClientConfig = useSetAtom(sessionConfigAtom(configId));

  const { error } = useQuery(["config"], () => getConfig(configId), {
    enabled: navigator.onLine,
    suspense: true,
    useErrorBoundary: false,
    onSuccess: (data: ClientConfig) => {
      setClientConfig(data);
    },
    onError: (error: string) => {
      datadogRum.addError(error, {
        message: `Failed to get config for configId ${configId}`,
      });
    },
  });
  if (error) {
    datadogRum.addError(error, {
      message: `Failed to get config for configId ${configId}`,
    });
  }
};
