import { Text, Title, createStyles } from "@mantine/core";

import ClientTranslation from "../../components/ClientTranslation";
import { Layout } from "../../components/Layout";
import { TEXT_STYLES } from "../../components/Text";
import { useClientTranslation } from "../../hooks/useClientTranslation";
import { usePageTracking } from "../../hooks/usePageTracking";

const useStyles = createStyles((theme) => ({
  heading: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "center",
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  ...TEXT_STYLES,
  XXLSemibold: {
    ...TEXT_STYLES.XXLSemibold,
    margin: "auto",
  },
}));

export const NotFoundPage = () => {
  usePageTracking({ name: "404" });

  const { classes } = useStyles();
  const { t } = useClientTranslation();

  return (
    <Layout>
      <div className={classes.inner}>
        <div className={classes.heading}>
          <Title className={classes.XXLSemibold}>
            <ClientTranslation i18nKey={"404"}>{t("404")}</ClientTranslation>
          </Title>
          <Text className={classes.MediumRegular}>
            <ClientTranslation i18nKey={"page.not_found"}>{t("page.not_found")}</ClientTranslation>
          </Text>
        </div>
      </div>
    </Layout>
  );
};
