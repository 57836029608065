import * as yup from "yup";
import { isPossiblePhoneNumber } from "libphonenumber-js";

export const COUNTRY_CODE = "US";

declare module "yup" {
  interface StringSchema {
    phoneNumber(msg?: string): this;
  }
}

yup.addMethod(yup.string, "phoneNumber", function (errorMessage) {
  return this.test("phone-number", errorMessage, function (value) {
    const { path, createError } = this;
    return (value && isPossiblePhoneNumber(value, COUNTRY_CODE)) || createError({ path, message: errorMessage });
  });
});

export const schema = yup
  .object({
    firstName: yup.string().required("Enter your first name"),
    lastName: yup.string().required("Enter your last name"),
    email: yup.string().required("Enter your email address").email("Enter valid email address"),
    phoneNumber: yup.string().required("Enter your phone number").phoneNumber("Enter valid phone number"),
    toc: yup.boolean().oneOf([true]),
  })
  .required();
