export const TEXT_STYLES = {
  SmallRegular: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  BaseRegular: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
  },
  MediumRegular: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  MediumMedium: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
  },
  XXLSemibold: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "40px",
  },
};
