import { useAtomValue } from "jotai";
import { AsYouType } from "libphonenumber-js";
import { useParams } from "react-router-dom";
import { createStyles, Text, Title } from "@mantine/core";

import { TEXT_STYLES } from "../../components/Text";
import { Layout } from "../../components/Layout";
import { usePageTracking } from "../../hooks/usePageTracking";
import { CheckOutline } from "../../components/Icons/CheckOutline";

import { phoneNumberAtom } from "../../atoms";

const useStyles = createStyles(() => ({
  inner: {
    marginTop: "200px",
    marginLeft: "56px",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  body: {
    maxWidth: "460px",
    marginTop: "16px",
    marginBottom: "36px",
  },
  ...TEXT_STYLES,
}));

export const LinkSentPage = () => {
  usePageTracking({ name: "Link sent - view" });
  const { bodyShopId } = useParams<{ bodyShopId: string }>();
  const phoneNumber = useAtomValue(phoneNumberAtom(bodyShopId)) as string;
  const { classes } = useStyles();
  const formattedPhoneNumber = new AsYouType("US").input(phoneNumber);

  return (
    <Layout>
      <div className={classes.inner}>
        <div className={classes.heading}>
          <CheckOutline />
          <Title className={classes.XXLSemibold}>Your link is on its way!</Title>
        </div>
        <div className={classes.body}>
          <Text className={classes.MediumRegular}>
            We’re sending a text to <strong>{formattedPhoneNumber}</strong>. It may take a couple seconds for you to
            receive the text.
          </Text>
        </div>
      </div>
    </Layout>
  );
};
