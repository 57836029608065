import { MantineThemeOverride } from "@mantine/core";
import { useAtomValue } from "jotai";

import { configAtom } from "../atoms/config";

export const useGetThemeOverride = () => {
  const {
    theme: { colours, fonts },
  } = useAtomValue(configAtom);

  const themeOverride: MantineThemeOverride = {
    fontFamily: fonts.family,
    colors: {
      primary: [
        "#3A3A3A",
        "#353535",
        "#303030",
        "#2C2C2C",
        "#282828",
        "#242424",
        colours.primary, // override from config
        "#1E1E1E",
        "#1B1B1B",
        "#181818",
      ],
      secondary: [
        "#aadcc1",
        "#95d3b2",
        "#7fcaa2",
        "#6ac193",
        "#55b983",
        "#3fb074",
        colours.secondary, // override from config
        colours.hover ?? colours.secondary,
        "#228650",
        "#1d7546",
      ],
      negative: [
        "#D9CBCB",
        "#CCB3B2",
        "#C29A99",
        "#BC817F",
        "#BB6764",
        "#BF4A45",
        colours.negative, // override from config
        "#9F3A36",
        "#863D3A",
        "#713D3B",
      ],
      terms: [
        colours.terms, // override from config
        "#FFFFFF"
      ]
    },
  };

  return { themeOverride };
};
