import { Text, Title, createStyles } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import NotFoundPage from "pages/NotFoundPage";

import { configAtom } from "atoms/config";

import { sessionFinishedAtom } from "../../atoms";
import ClientTranslation from "../../components/ClientTranslation";
import { Form } from "../../components/Form";
import { Layout } from "../../components/Layout";
import { TEXT_STYLES } from "../../components/Text";
import { useClientTranslation } from "../../hooks/useClientTranslation";
import { usePageTracking } from "../../hooks/usePageTracking";
import usePageValidation from "./usePageValidation";

const useStyles = createStyles((theme) => ({
  heading: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: "480px",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
  },
  ...TEXT_STYLES,
}));

export const FormPage = () => {
  usePageTracking({ name: "Form - view" });
  const navigate = useNavigate();
  const { bodyShopId } = useParams<{ bodyShopId: string }>();
  const [sessionFinished] = useAtom(sessionFinishedAtom(bodyShopId));

  const {
    clientId,
    formPage: { validationStrategy },
  } = useAtomValue(configAtom);

  const { classes } = useStyles();
  const { t } = useClientTranslation();
  const { valid, loading } = usePageValidation({ reference: bodyShopId, clientId, strategy: validationStrategy });

  useEffect(() => {
    if (sessionFinished) {
      navigate("./link_sent");
    }
  }, [sessionFinished, navigate]);

  // We don't have a loading state in CCP yet 🤔
  if (loading) {
    return null;
  }

  if (!valid) {
    return <NotFoundPage />;
  }

  return (
    <Layout>
      <div className={classes.inner}>
        <div className={classes.heading}>
          <Title className={classes.XXLSemibold}>
            <ClientTranslation i18nKey={"welcome.title"}>{t("welcome.title")}</ClientTranslation>
          </Title>
          <Text className={classes.MediumRegular}>
            <ClientTranslation i18nKey={"welcome.description"}>{t("welcome.description")}</ClientTranslation>
          </Text>
        </div>
        <Form />
      </div>
    </Layout>
  );
};
