import { Header, Image, createStyles } from "@mantine/core";
import { useAtomValue } from "jotai";

import { configAtom } from "../../atoms/config";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colors.primary[6],
    borderBottom: 0,
  },
  inner: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
  },
}));

const AppHeader = () => {
  const { classes } = useStyles();
  const {
    clientId,
    theme: { logo },
  } = useAtomValue(configAtom);
  const { url, height = 48 } = logo;

  return (
    <Header height="auto" className={classes.header}>
      <div className={classes.inner}>
        <Image height={height} width="auto" src={url} alt={`${clientId} logo`} />
      </div>
    </Header>
  );
};

export { AppHeader };
