import { MantineProvider } from "@mantine/core";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { useGetThemeOverride } from "./hooks/useGetThemeOverride";
import { useInitialiseConfigFromConfigId } from "./hooks/useInitialiseConfigFromConfigId";
import { useInitialiseEventTracking } from "./hooks/useInitialiseEventTracking";
import { useInitialiseLocalisation } from "./hooks/useInitialiseLocalisation";
import { FormPage, LinkSentPage, NotFoundPage } from "./pages";

function App() {
  useInitialiseEventTracking();
  useInitialiseConfigFromConfigId();
  useInitialiseLocalisation();

  const { themeOverride } = useGetThemeOverride();
  return (
    <MantineProvider theme={themeOverride} withNormalizeCSS withGlobalStyles>
      <Router>
        <Routes>
          <Route path="/:bodyShopId" element={<FormPage />} />
          <Route path="/:bodyShopId/link_sent" element={<LinkSentPage />} />
          <Route path="/" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
