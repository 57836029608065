import { init } from "@amplitude/marketing-analytics-browser";
import { useQuery } from "@tanstack/react-query";

export const useInitialiseEventTracking = () => {
  useQuery(
    ["initialiseAmplitude"],
    () =>
      init(window.__RUNTIME_ENV__.AMPLITUDE_API_KEY, undefined, {
        disableCookies: true,
        trackingOptions: {
          ipAddress: false,
        },
        attribution: {
          trackPageViews: true,
        },
      }).promise,
    {
      enabled: !!window?.__RUNTIME_ENV__?.AMPLITUDE_API_KEY,
    }
  );
};
