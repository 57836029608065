import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";
import App from "./App";
import { Global, LoadingOverlay } from "@mantine/core";
import { globalStyles } from "./theme/global";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { isLocalEnvironment, isProdEnvironment } from "./utils/environment";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";

if (!isLocalEnvironment()) {
  datadogRum.init({
    applicationId: "1f41a90f-41b6-44d8-9654-1f25b5a71e17",
    clientToken: "pube98dc5dc3860f3fc2114851c35d3cc2f",
    site: "datadoghq.eu",
    service: "coa-customer-pay",
    version: process.env.REACT_APP_VERSION,
    env: window?.__RUNTIME_ENV__?.ENVIRONMENT || "local",
    sampleRate: 100,
    /**
     * Premium sessions include replay recordings
     * These now are only recorded in production for only 5% of users
     * */
    premiumSampleRate: isProdEnvironment() ? 5 : 0,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient({});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<LoadingOverlay visible loaderProps={{ color: "primary" }} />}>
          <Global styles={globalStyles} />
          <GoogleReCaptchaProvider reCaptchaKey={window?.__RUNTIME_ENV__.RECAPTCHA_KEY}>
            <App />
          </GoogleReCaptchaProvider>
        </Suspense>
      </I18nextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
