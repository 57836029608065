import { atomWithStorage, createJSONStorage } from "jotai/utils";
import localforage from "localforage";

const atomPersistor = {
  ...localforage,
  setItem: async <Value>(key: string, value: Value) => {
    localforage.setItem<Value>(key, value);
  },
};

export const createPersistedAtom = <Value>(key: string, defaultValue: Value) =>
  atomWithStorage(
    key,
    defaultValue,
    createJSONStorage(() => atomPersistor)
  );