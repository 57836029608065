import { createStyles, Text, Anchor } from "@mantine/core";

import { TEXT_STYLES } from "../Text";
import { useAtomValue } from "jotai";

import { configAtom } from "../../atoms/config";

const useStyles = createStyles((theme) => ({
  footer: {
    display: "flex",
    height: "147px",
    gap: "4px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.primary[6],
  },
  tocContainer: {
    display: "flex",
    gap: "4px",
  },
  ...TEXT_STYLES,
}));

const AppFooter = () => {
  const { classes } = useStyles();
  const { formPage: { termsLink, privacyPolicyLink }, theme } = useAtomValue(configAtom);

  return (
    <div className={classes.footer}>
      <Text color={theme.colours.terms ?? "white"} className={classes.SmallRegular}>
        Powered by Tractable © 2021-2024. All rights reserved.
      </Text>
      <div className={classes.tocContainer}>
        <Anchor
          href={privacyPolicyLink}
          target="_blank"
          className={classes.SmallRegular}
          style={{ color: theme.colours.terms ?? "white", textDecoration: "underline" }}
        >
          Privacy Policy
        </Anchor>
        <Text color={theme.colours.terms ?? "white"} className={classes.SmallRegular}>
          |
        </Text>
        <Anchor
          href={termsLink}
          target="_blank"
          className={classes.SmallRegular}
          style={{ color: theme.colours.terms ?? "white", textDecoration: "underline" }}
        >
          Terms & Conditions
        </Anchor>
      </div>
    </div>
  );
};

export { AppFooter };
