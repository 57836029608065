import { urlConfigIdAtom } from "atoms/urlConfigIdAtom";
import { useAtomValue } from "jotai";
import { getUserLocale } from "utils/environment";

import { configAtom } from "../atoms/config";

export default function useClientDetails() {
  const { clientId, brand, locale } = useAtomValue(configAtom);
  const urlConfigId = useAtomValue(urlConfigIdAtom);  
  const userLocale = getUserLocale(locale)

  return { clientId, brand, urlConfigId, userLocale };
}
