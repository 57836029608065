import { Locale } from "../types/locale";
import { ClientConfig } from "./types";

export const BASE_CONFIG: ClientConfig = {
  clientId: "caliber",
  locale: Locale.English_GB,
  theme: {
    logo: {
      url: "/assets/caliberLogo.png",
    },
    colours: {
      primary: "#18A764",
      secondary: "#212121",
      negative: "#CED4DA",
      terms: "#FFFFFF"
    },
    buttons: {
      borderRadius: 0,
    },
    fonts: {
      url: "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&display=swap",
      family: "IBM Plex Sans, sans-serif",
    },
  },
  formPage: {
    termsLink: "https://www.caliber.com/terms",
    privacyPolicyLink: "https://www.caliber.com/privacy-policy",
    illustrations: {
      background: {
        url: "/assets/bodyshopView.jpg",
      },
    },
  },
};
