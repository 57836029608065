import { SetStateAction, atom } from "jotai";
import { atomFamily } from "jotai/utils";
import merge from "lodash.merge";

import { ClientConfig, DEFAULT_BASE_CONFIG } from "../clientConfig";
import { createPersistedAtom } from "./createPersistedAtom";
import { DeepPartial } from "./typeUtils";
import { urlConfigIdAtom } from "./urlConfigIdAtom";

export const sessionConfigAtom = atomFamily((clientId: string) =>
  createPersistedAtom<ClientConfig>(`config-${clientId}`, DEFAULT_BASE_CONFIG)
);

export const configAtom = atom(
  (get) => get(sessionConfigAtom(get(urlConfigIdAtom))),
  (get, set, update: SetStateAction<ClientConfig>) => set(sessionConfigAtom(get(urlConfigIdAtom)), update)
);

export const createConfigFromBaseAtom = atom<null, DeepPartial<ClientConfig>>(null, (_get, set, update) =>
  set(configAtom, merge({}, DEFAULT_BASE_CONFIG, update))
);

export const themeConfigAtom = atom((get) => get(configAtom).theme);
