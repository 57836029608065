import { useEffect } from "react";

import { Event } from "../types/Event";

import { useEventTracking } from "./useEventTracking";

export const usePageTracking = ({ name, properties }: Event) => {
  const { trackEvent } = useEventTracking();

  useEffect(() => {
    trackEvent({ name, properties });
  }, [name, properties, trackEvent]);
};
