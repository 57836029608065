export const CheckOutline = ({ fill = "#2aa764" }: { fill?: string }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Check outline</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1C18.7273 1 21.2583 1.68181 23.593 3.04545C25.8451 4.36778 27.6322 6.16528 28.9545 8.43802C30.3182 10.7727 31 13.2934 31 16C31 18.7066 30.3182 21.2273 28.9545 23.562C27.6322 25.8347 25.8347 27.6322 23.562 28.9545C21.2273 30.3182 18.7066 31 16 31C13.2934 31 10.7727 30.3182 8.43802 28.9545C6.16528 27.6322 4.36778 25.8451 3.04545 23.593C1.68181 21.2583 1 18.7273 1 16C1 13.2727 1.68181 10.7417 3.04545 8.40702C4.36778 6.15495 6.16528 4.36778 8.43802 3.04545C10.7727 1.68181 13.2934 1 16 1ZM15.9996 3.88222C13.8095 3.88222 11.7744 4.42974 9.89423 5.52479C8.05538 6.59917 6.59878 8.05577 5.5244 9.89462C4.42935 11.7748 3.88184 13.8099 3.88184 16C3.88184 18.1901 4.42935 20.2252 5.5244 22.1054C6.59878 23.9442 8.05538 25.4008 9.89423 26.4752C11.7744 27.5702 13.8095 28.1178 15.9996 28.1178C18.1897 28.1178 20.2248 27.5702 22.105 26.4752C23.9438 25.4008 25.4004 23.9442 26.4748 22.1054C27.5699 20.2252 28.1174 18.1901 28.1174 16C28.1174 13.8099 27.5699 11.7748 26.4748 9.89462C25.4004 8.05577 23.9438 6.59917 22.105 5.52479C20.2248 4.42974 18.1897 3.88222 15.9996 3.88222ZM24.4919 11.7851C24.6365 11.9298 24.7089 12.1002 24.7089 12.2965C24.7089 12.4928 24.6365 12.6632 24.4919 12.8079L14.0477 23.1591C13.9031 23.3037 13.7326 23.376 13.5363 23.376C13.3401 23.376 13.1696 23.3037 13.025 23.1591L7.50845 17.6116C7.36382 17.4669 7.2915 17.2965 7.2915 17.1002C7.2915 16.9039 7.36382 16.7335 7.50845 16.5888L8.90307 15.2252C9.0477 15.0806 9.21816 15.0083 9.41444 15.0083C9.61072 15.0083 9.78117 15.0806 9.9258 15.2252L13.5518 18.8822L22.1056 10.3905C22.2502 10.2459 22.4206 10.1736 22.6169 10.1736C22.8132 10.1736 22.9837 10.2459 23.1283 10.3905L24.4919 11.7851Z"
      fill={fill}
    />
  </svg>
);
